
import { LoginService } from './services/login.service';
import { Component, HostListener } from '@angular/core';
import { SessionService } from './services/session.services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers:[SessionService]
})


export class AppComponent {
  title = 'Gypsum Express LTD';
  loggedIn = false;

  constructor(private sessionService:SessionService, private loggingService: LoginService){}


  @HostListener('window:beforeunload', ['$event'])

  public beforeunloadHandler($event) {
    this.onClose();
  }
  
  onClose(){
    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'ClosedApp','Closed Application' )
    .subscribe((data)=>{});
  }

  afterLogin(statusData:{loginStatus:string}){
    console.log('status' + statusData.loginStatus);
    if (statusData.loginStatus == 'success') {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }

  }


  
  // ngOnDestroy() {
    // this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'ClosedWindow',' closed window' )
    // .subscribe((data)=>{});
  // }
}
