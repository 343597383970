import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from './../../services/session.services';
import { LoginService } from './../../services/login.service';
import { DomSanitizer } from '@angular/platform-browser'
import { PlatformScienceService } from 'src/app/services/platform-science.service';
import { TrainingVideoModel } from 'src/app/models/trainingVideo.model';

@Component({
  selector: 'app-platform-science',
  templateUrl: './platform-science.component.html',
  styleUrls: ['./platform-science.component.css']
})
export class PlatformScienceComponent implements OnInit {
  videoLink: string;
  videoTitle: string;
  videoList: TrainingVideoModel[];

  constructor(private modalService: NgbModal
    , config: NgbModalConfig
    , private sessionService: SessionService
    , private sanitizer: DomSanitizer
    , private loggingService:LoginService
    , private psService: PlatformScienceService) 
    {
     config.backdrop='static';
     config.keyboard=false;
    }

  ngOnInit(): void {
    this.psService.getPSVideo().subscribe(
      (data: TrainingVideoModel[]) => {
        this.videoList = data;
        console.log(this.videoList);
      }
    )
  }

  open(content, link, title){
    this.modalService.open(content, {centered:true, size:'lg'});
    this.videoLink = link;
    this.videoTitle = title;

    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'OpenedVideo',this.videoTitle)
      .subscribe((data)=>{});
  }

  closeModal(title:string){
    this.modalService.dismissAll('finished');
    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'ClosedVideo', title)
    .subscribe((data)=>{});
    
  }


  openSimulator(content, link, title){
    this.modalService.open(content, {centered:true, size:'lg'});
    this.videoLink = link;
    this.videoTitle = title;

    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'OpenedVideo',this.videoTitle)
      .subscribe((data)=>{});
  }

  safeURL(link) {
    console.log('Safe URL: ' + link);
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  launchSimulator(link){
    var newWindow = window.open(link);
  }
  
  openNewPDF (link:string, title:string) {
    var newWindow = window.open(link);
    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'OpenedPDF',title)
      .subscribe((data)=>{});
  }

}
