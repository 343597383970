import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class FreightlinerTrainingService {
    results: any;
    constructor(private http: HttpClient) { }

    getVideos(vin: string) {
      let url = '';
      if (!environment.production) {
        //internal
        url = 'http://appserv/rest/portal/driver-site/freightlinerTraining.php?vin=';
      }

      
      if (environment.production) {
        //external
        url = '/scripts/freightlinerTraining-ext.php?vin=';
      }

      url = url + vin;

      console.log('url' + url);

      return this.http.get(url);

    }

  }
