import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from './../../services/session.services';
import { LoginService } from './../../services/login.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-international',
  templateUrl: './international.component.html',
  styleUrls: ['./international.component.css']
})
export class InternationalComponent implements OnInit {
  videoLink: string;
  videoTitle: string;
  tractorModel: string;

  constructor(private modalService: NgbModal
              , config: NgbModalConfig
              , private sessionService: SessionService
              , private sanitizer: DomSanitizer
              , private loggingService:LoginService) 
              {
               config.backdrop='static';
               config.keyboard=false;
              }


  ngOnInit() {
    this.tractorModel = this.sessionService.sessionInfo[0].tractorModel;
  }

  
  open(content, link, title){
    this.modalService.open(content, {centered:true, size:'lg'});
    this.videoLink = link;
    this.videoTitle = title;

    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'OpenedVideo',this.videoTitle)
      .subscribe((data)=>{});
  }

  closeModal(title:string){
    this.modalService.dismissAll('finished');
    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'ClosedVideo', title)
    .subscribe((data)=>{});
    
  }


  openSimulator(content, link, title){
    this.modalService.open(content, {centered:true, size:'lg'});
    this.videoLink = link;
    this.videoTitle = title;

    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'OpenedVideo',this.videoTitle)
      .subscribe((data)=>{});
  }

  safeURL(link) {
    console.log('Safe URL: ' + link);
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  launchSimulator(link){
    var newWindow = window.open(link);
  }
  
  openNewPDF (link:string, title:string) {
    var newWindow = window.open(link);
    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'OpenedPDF',title)
      .subscribe((data)=>{});
  }


}
