import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { FlinerComponent } from './training/fliner/fliner.component';
import { FreightlinerTrainingService } from './services/fliner.training.services';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { TrainingComponent } from './training/training.component';
import { IvgComponent } from './training/ivg/ivg.component';
import { FormsModule } from '@angular/forms';
import { CustomerSpecificComponent } from './training/customer-specific/customer-specific.component';
import { CopilotComponent } from './training/copilot/copilot.component';
import { TractorsComponent } from './training/tractors/tractors.component';
import { TrailersComponent } from './training/trailers/trailers.component';
import { TipsComponent } from './training/tips/tips.component';
import { MechanicsComponent } from './training/mechanics/mechanics.component';
import { McpComponent } from './training/mcp/mcp.component';
import { InternationalComponent } from './training/international/international.component';
import { SafetyComponent } from './training/safety/safety.component';
import { PlatformScienceComponent } from './training/platform-science/platform-science.component';
import { VectorComponent } from './vector/vector.component';
import { HumanResourcesComponent } from './training/human-resources/human-resources.component';



@NgModule({
  declarations: [
    AppComponent,
    FlinerComponent,
    LoginComponent,
    TrainingComponent,
    IvgComponent,
    CustomerSpecificComponent,
    CopilotComponent,
    TractorsComponent,
    TrailersComponent,
    TipsComponent,
    MechanicsComponent,
    McpComponent,
    InternationalComponent,
    SafetyComponent,
    PlatformScienceComponent,
    VectorComponent,
    HumanResourcesComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    // NgbModule.forRoot(),
    NgbModule,
    AppRoutingModule,
    FormsModule

  ],
  exports: [
    NgbModule,
    BrowserModule
  ],
  providers: [FreightlinerTrainingService],
  bootstrap: [AppComponent]
})

export class AppModule { }
