import { EventEmitter, Injectable } from '@angular/core';

@Injectable()

export class SessionService {
    
    sessionInfo =[{driverCode:'', driverPassword:'', tractorID: '', employeeType:'',tractorModel:''}];

    constructor(){}

    driverSession = new EventEmitter<string>();

    addSessionDriverCode(driverCode:string, employeeType: string){
        this.sessionInfo[0].driverCode = driverCode;
        this.sessionInfo[0].employeeType = employeeType;
    }

    addSessionTractor (tractorID:string, tractorModel:string){
        this.sessionInfo[0].tractorID = tractorID;
        this.sessionInfo[0].tractorModel = tractorModel;
    }

    


}