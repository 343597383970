import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment } from './../../environments/environment';


@Injectable({
    providedIn: 'root'
  })

export class TractorInfoService {

    constructor(private http: HttpClient) { }

    getTractorInfo(tractor: string) {
        let url = '';
        if (!environment.production) {
            //internal
            url = 'http://appserv/rest/portal/driver-site/tractor_info.php?tractor=';
        }

        
        if (environment.production) {
            //external
            url = '/scripts/tractor_info-ext.php?tractor=';
        }


        url = url + tractor;


        return this.http.get(url);

    }
}
