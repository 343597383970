import { SessionService } from './../services/session.services';
import { LoginService} from './../services/login.service';
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { loginModel } from './../models/login.model'
import {environment } from './../../environments/environment';

import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loginResponse: loginModel[];
  invalidLogin: string;
  @ViewChild ('f') loginForm: NgForm;
  

  @Output() loggedIn = new EventEmitter <{loginStatus: string}>()
  
  //loggedIn: string;

  constructor(private loginService: LoginService, private sessionService:SessionService) { }

  ngOnInit() {
    
  }
  onLogin(driverID: string, driverPassword: string) {
    console.log('Logging In....')
    this.loginResponse = [];
    this.invalidLogin = '';
    
    this.loginService.getDriverInfo(driverID,driverPassword)
      .subscribe((data:loginModel[]) => {
        this.loginResponse = data;
        console.log (this.loginResponse);
        console.log(this.loginResponse[0]['LoginStatus'])
        
        if (this.loginResponse[0]['LoginStatus'] == 'failed') {
          this.invalidLogin = 'Invalid User Name or Password';
          this.loggedIn.emit({loginStatus: this.loginResponse[0]['LoginStatus']})
          
          this.loginService.postDriverLogging(driverID,'Log In','Login Failed')
            .subscribe((data)=>{});

        } else {
          this.loggedIn.emit({loginStatus:this.loginResponse[0]['LoginStatus']})

          this.loginService.postDriverLogging(driverID,'Log In','Login Successful')
            .subscribe((data)=>{});
          
          this.sessionService.addSessionDriverCode(driverID, this.loginResponse[0]['DriverType']);
        }
      });
  }

      
  onSubmit(){
    console.log('Submit Logging in...');
    // console.log(this.loginForm);
    // console.log(this.loginForm.value.password);
    
    

    this.loginResponse = [];
    this.invalidLogin = '';
    
    this.loginService.getDriverInfo(this.loginForm.value.driverCode,this.loginForm.value.password)
      .subscribe((data:loginModel[]) => {
        this.loginResponse = data;
        console.log (this.loginResponse);
        console.log(this.loginResponse[0]['LoginStatus'])
        
        if (this.loginResponse[0]['LoginStatus'] == 'failed') {
          this.invalidLogin = 'Invalid User Name or Password';
          this.loggedIn.emit({loginStatus: this.loginResponse[0]['LoginStatus']})
          
          this.loginService.postDriverLogging(this.loginForm.value.driverCode,'Log In','Login Failed')
            .subscribe((data)=>{});

        } else {
          this.loggedIn.emit({loginStatus:this.loginResponse[0]['LoginStatus']})

          this.loginService.postDriverLogging(this.loginForm.value.driverCode,'Log In','Login Successful')
            .subscribe((data)=>{});
          
          this.sessionService.addSessionDriverCode(this.loginForm.value.driverCode, this.loginResponse[0]['DriverType']);
        }
      });
  }


}
