import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { Time } from '@angular/common';
// import { url } from 'inspector';
import {environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
  })

export class LoginService {
    env:string = 'dev';

    constructor(private http: HttpClient) { }


    getDriverInfo(driverID: string, driverPassword:string) {
        let url =''
        //internal testing
        if (!environment.production) {
            url = 'http://appserv:1819/rest/portal/driver-site/driver_login.php'; //new using post
        }
        
        if (environment.production) {
            //external
            url = '/scripts/driver_login-ext.php'; //new using post
        }

        

        let postData = 'driverID=' + driverID + '&password=' + driverPassword;
        var postHeaders = {headers: {'Content-Type':'application/x-www-form-urlencoded'}};
        // console.log('driverPassword: ' + driverPassword);
        return this.http.post(url,postData, postHeaders);
        // return this.http.get(url);

    }

    postDriverLogging (driverID: string, event:string, eventDescription:string) {
        let loggingUrl = ''
        
        if (!environment.production) {
        //internal
            loggingUrl = 'http://appserv/rest/portal/driver-site/driver_logging.php';
        }

        if (environment.production) {
            //external
            loggingUrl = '/scripts/driver_logging-ext.php';
        }

        if (driverID=='') {
            driverID = 'blank';
        }



        let postData = 'driverID=' + driverID + '&event=' + event + '&eventDescription=' +eventDescription;
        postData = encodeURI(postData);

        var postHeaders = {headers: {'Content-Type':'application/x-www-form-urlencoded'}};

        var req = {
            method:'POST',
            url:loggingUrl,
            headers: {
                'Content-Type': 'applications/x-www-form-urlencoded'
            },
            data:postData
        }
        console.log('Post Data: ' + postData);
        //  return this.http.post(loggingUrl,postData);
        return this.http.post(loggingUrl,postData, postHeaders);
        //return this.http.request(req);
        //return this.http.get(url);

    }
}
