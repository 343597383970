import { SessionService } from './../../services/session.services';
import { LoginService } from './../../services/login.service';
import { TractorInfoService } from './../../services/tractor-info.services';
import { FreightlinerTrainingService } from './../../services/fliner.training.services';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { freightLinerModel} from '../../models/fliner.training.model';
import { TractorInfoModel } from '../../models/tractorInfo.model';

import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-fliner',
  templateUrl: './fliner.component.html',
  styleUrls: ['./fliner.component.css']
})
export class FlinerComponent implements OnInit, OnChanges {
  
  @Input() flVin: string;
  //@Input() videos: freightLinerModel[];

  videos: freightLinerModel[];
  tractorInfo: TractorInfoModel[];
  videoLink: string;
  videoTitle: string;

  closeResult: string;
  
  //constructor(private freightlinerTrainingService: FreightlinerTrainingService) { }
  constructor(private freightlinerTrainingService: FreightlinerTrainingService
            , private loggingService: LoginService
            , private sessionService: SessionService
            , private modalService: NgbModal

            , config: NgbModalConfig) 
            { config.backdrop='static';
              config.keyboard=false;
             }

  
  
  ngOnInit() {
    this.videos = [];

    this.freightlinerTrainingService.getVideos(this.flVin)
      .subscribe((data: freightLinerModel[]) => {
        this.videos = data;
         console.log(this.videos);
      })
  }

  ngOnChanges(){
    this.videos = [];

    this.freightlinerTrainingService.getVideos(this.flVin)
    .subscribe((data: freightLinerModel[]) => {
      this.videos = data;
      console.log(this.videos);
    })
  }
  
  open(content, link, title, type){

    this.modalService.open(content, {centered:true, size:'lg'});
    //   .result.then ((result)=> {
    //       this.closeResult = 'Closed with: ${result}';
    //     }, (reason) => {
    //       this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    // });
    if (type != 'TRANSMISSION') {
      this.videoLink = `https://content.jwplatform.com/videos/${link}-VtIvXdJp.mp4`;
    } else {
      this.videoLink = `https://content.jwplatform.com/videos/${link}-MWb0rjkL.mp4`;
    }
    
    
    this.videoTitle = title;

    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'OpenedVideo',this.videoTitle)
      .subscribe((data)=>{});
  }

  closeModal(title:string){
    this.modalService.dismissAll('finished');
    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'ClosedVideo', title)
    .subscribe((data)=>{});
    
  }
  openNewPDF (link:string, title:string) {
    var newWindow = window.open(link);
    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'OpenedPDF',title)
      .subscribe((data)=>{});
  }


 


}
