//import { FreightlinerTrainingService } from './../services/fliner.training.services';
import { LoginService } from './../services/login.service';
import { TractorInfoService } from './../services/tractor-info.services';
import { TractorInfoModel } from './../models/tractorInfo.model';
import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { SessionService } from '../services/session.services';
import { freightLinerModel} from '../models/fliner.training.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})

export class TrainingComponent implements OnInit {
  @ViewChild ('t') tractorForm: NgForm;

  tractorInfo: TractorInfoModel[];
  // showNav = false;
  showNav = true;
  showTelematicsNav = false;
  showTractorNav = false;
  showFreightLiner:string;
  showTractorInfo = false;
  showTelematicsInfo = false;
  showCoPilot = false;
  showPlatformScience = false;
  showVector = false;
  showCustomers = false;
  showTractors = false;
  activeButton: string;
  telematics:string;
  tractorMake: string;
  showTrailers = false;
  showTips = false;
  showMechanics = false;
  isMechanic = false;
  tractorModel: string;
  vin: string;
  tractor: string;
  showSafety: boolean = false;
  showHR: boolean = false;

  constructor(private tractorInfoService:TractorInfoService
            , private loggingService: LoginService
            , private sessionService: SessionService
             ) { }

  videos: freightLinerModel[];

  ngOnInit() {
  }

  @HostListener('window:beforeunload', ['$event'])

  public beforeunloadHandler($event) {
    this.onClose();
  }

  onClose(){
    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'ClosedApp','Closed Application' )
    .subscribe((data)=>{});
  }

  onGetTractor () {
    this.showNav = false;
    this.tractor = this.tractorForm.value.truckNumber;
    this.tractorInfo = [];
    this.tractorModel = '';
    this.showNothing();

    this.tractorInfoService.getTractorInfo(this.tractor)
      .subscribe((data: TractorInfoModel[]) => {
        this.tractorInfo = data;
        console.log ('Tractor Info');
        console.log (this.tractorInfo);
        // console.log (this.tractorInfo[0]['FreightlinerVin'])
        this.showNav = true;
        this.showTractorNav = true;
        this.telematics = this.tractorInfo[0]['Telematics'];

        this.showTelematicsNav = true;

        console.log('Telematics: ' + this.telematics);

        this.tractorMake = this.tractorInfo[0]['Make'];
        this.tractorModel = this.tractorInfo[0]['Model'];
        this.showFreightLiner = this.tractorInfo[0]['Make'];
        this.sessionService.addSessionTractor(this.tractor, this.tractorModel);

        /* Begin Freight Liner */
        if (this.tractorInfo[0]['Make']=='FREIGHTLINER') {


          this.vin = this.tractorInfo[0]['FreightlinerVin'];

        }
        /* End FreightLiner */
      });

    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'Tractor','Entered Tractor: ' + this.tractor)
      .subscribe((data)=>{});

      console.log('Model: ' + this.tractorModel);



    if (this.sessionService.sessionInfo[0]['employeeType']=="mechanic" || this.sessionService.sessionInfo[0]['employeeType']=="admin" ) {
      this.isMechanic = true;
    }

  }


  showTractor () {
    this.activeButton = 'tractor';
    this.showTractorInfo = true;
    this.showTelematicsInfo = false;
    this.showCoPilot = false;
    this.showCustomers = false;
    this.showTractors = false;
    this.showTrailers = false;
    this.showTips= false;
    this.showMechanics = false;
    this.showSafety = false;
    this.showPlatformScience = false;
    this.showVector = false;
    this.showHR = false;
  }

  showTelematics(){
    this.activeButton = 'telematics';
    this.showTractorInfo = false;
    this.showTelematicsInfo = true;
    this.showCoPilot = false;
    this.showCustomers = false;
    this.showTractors = false;
    this.showTips= false;
    this.showMechanics = false;
    this.showSafety = false;
    this.showPlatformScience = false;
    this.showVector = false;
    this.showHR = false;
  }

  showCoPilotInfo(){
    this.activeButton = 'copilot';
    this.showTractorInfo = false;
    this.showTelematicsInfo = false;
    this.showCoPilot = true;
    this.showCustomers = false;
    this.showTractors = false;
    this.showTips= false;
    this.showMechanics = false;
    this.showSafety = false;
    this.showPlatformScience = false;
    this.showVector = false;
    this.showHR = false;
  }

  showPlatformScienceInfo(){
    this.activeButton = 'platformScience';
    this.showTractorInfo = false;
    this.showTelematicsInfo = false;
    this.showCoPilot = false;
    this.showCustomers = false;
    this.showTractors = false;
    this.showTips= false;
    this.showMechanics = false;
    this.showSafety = false;
    this.showPlatformScience = true;
    this.showVector = false;
    this.showHR = false;
  }

  showVectorInfo(){
    this.activeButton = 'vector';
    this.showTractorInfo = false;
    this.showTelematicsInfo = false;
    this.showCoPilot = false;
    this.showCustomers = false;
    this.showTractors = false;
    this.showTips= false;
    this.showMechanics = false;
    this.showSafety = false;
    this.showPlatformScience = false;
    this.showVector = true;
    this.showHR = false;
  }

  showCustomerInfo(){
    this.activeButton = 'customers';
    this.showTractorInfo = false;
    this.showTelematicsInfo = false;
    this.showCoPilot = false;
    this.showCustomers = true;
    this.showTractors = false;
    this.showTrailers = false;
    this.showTips= false;
    this.showMechanics = false;
    this.showSafety = false;
    this.showPlatformScience = false;
    this.showVector = false;
    this.showHR = false;
  }

  showGenericTractorInfo(){
    this.activeButton = 'tractor-info';
    this.showTractorInfo = false;
    this.showTelematicsInfo = false;
    this.showCoPilot = false;
    this.showCustomers = false;
    this.showTractors = true;
    this.showTrailers = false;
    this.showTips= false;
    this.showMechanics = false;
    this.showSafety = false;
    this.showPlatformScience = false;
    this.showVector = false;
    this.showHR = false;
  }

  showTrailerInfo(){
    this.activeButton = 'trailer-info';
    this.showTractorInfo = false;
    this.showTelematicsInfo = false;
    this.showCoPilot = false;
    this.showCustomers = false;
    this.showTractors = false;
    this.showTrailers = true;
    this.showTips= false;
    this.showMechanics = false;
    this.showSafety = false;
    this.showPlatformScience = false;
    this.showVector = false;
    this.showHR = false;
  }

    showTipInfo(){
      this.activeButton = 'tip-info';
      this.showTractorInfo = false;
      this.showTelematicsInfo = false;
      this.showCoPilot = false;
      this.showCustomers = false;
      this.showTractors = false;
      this.showTrailers = false;
      this.showTips= true;
      this.showMechanics = false;
      this.showSafety = false;
      this.showPlatformScience = false;
      this.showVector = false;
      this.showHR = false;
    }

  showMechanicInfo(){
    this.activeButton = 'mechanic-info';
    this.showTractorInfo = false;
    this.showTelematicsInfo = false;
    this.showCoPilot = false;
    this.showCustomers = false;
    this.showTractors = false;
    this.showTrailers = false;
    this.showTips= false;
    this.showMechanics = true;
    this.showSafety = false;
    this.showPlatformScience = false;
    this.showVector = false;
    this.showHR = false;
  }

  showNothing () {
    this.activeButton = 'tractor';
    this.showTractorInfo = false;
    this.showTelematicsInfo = false;
    this.showCoPilot = false;
    this.showCustomers = false;
    this.showTractors = false;
    this.showTrailers = false;
    this.showTips= false;
    this.showMechanics = false;
    this.showSafety = false;
    this.showPlatformScience = false;
    this.showVector = false;
    this.showHR = false;
  }

  showSafetyInfo () {
    this.activeButton = 'saftey';
    this.showTractorInfo = false;
    this.showTelematicsInfo = false;
    this.showCoPilot = false;
    this.showCustomers = false;
    this.showTractors = false;
    this.showTrailers = false;
    this.showTips= false;
    this.showMechanics = false;
    this.showSafety = true;
    this.showPlatformScience = false;
    this.showVector = false;
    this.showHR = false;
  }

  showHRInfo () {
    this.activeButton = 'hr';
    this.showTractorInfo = false;
    this.showTelematicsInfo = false;
    this.showCoPilot = false;
    this.showCustomers = false;
    this.showTractors = false;
    this.showTrailers = false;
    this.showTips= false;
    this.showMechanics = false;
    this.showSafety = false;
    this.showPlatformScience = false;
    this.showVector = false;
    this.showHR = true;
  }

}
