import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
  })

export class PlatformScienceService {

    constructor(private http: HttpClient) { }

    getPSVideo() {
        let url = '';
        if (!environment.production) {
            //internal
            url = 'http://appserv/rest/portal/driver-site/platform-science-training.php';
        }

        if (environment.production) {
            //external
            url = '/scripts/platform-science-training-ext.php';
        }
        return this.http.get(url);
    }
}