import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from './../../services/login.service';
import { SessionService } from './../../services/session.services';


@Component({
  selector: 'app-customer-specific',
  templateUrl: './customer-specific.component.html',
  styleUrls: ['./customer-specific.component.css']
})
export class CustomerSpecificComponent implements OnInit {
  videoLink: string;
  videoTitle: string;

  constructor(private modalService: NgbModal,
              private loggingService: LoginService,
              private sessionService: SessionService,
              private sanitizer: DomSanitizer, 
              config: NgbModalConfig
              ) 
              { config.backdrop='static';
                config.keyboard=false;
               }

  ngOnInit() {

  }

  open(content, link, title){
    this.modalService.open(content, {centered:true, size:'lg'});
    this.videoLink = link;
    // this.videoLink = this.sainitizer.bypassSecurityTrustUrl(link);
    // console.log('video link' + this.videoLink);
    this.videoTitle = title;
    
    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'OpenedVideo',this.videoTitle)
      .subscribe((data)=>{});
  }

  closeModal(title:string){
    this.modalService.dismissAll('finished');
    this.loggingService.postDriverLogging(this.sessionService.sessionInfo[0]['driverCode'],'ClosedVideo', title)
    .subscribe((data)=>{});
    
  }

  safeURL(link) {
    console.log('Safe URL: ' + link);
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }


}
