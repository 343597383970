import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TrainingComponent } from './training/training.component';

const appRoutes: Routes = [
    {path: 'training', component: TrainingComponent}
]


@NgModule({
    imports:[
        RouterModule.forRoot(appRoutes,{useHash: true})
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {

}